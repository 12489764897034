import { default as dashboardruqszHAAT5Meta } from "/home/forge/master.smake.cloud/releases/v1.1.0/pages/dashboard.vue?macro=true";
import { default as entranceL8EYzT1CxEMeta } from "/home/forge/master.smake.cloud/releases/v1.1.0/pages/entrance.vue?macro=true";
import { default as indexnpb2Sq7bJZMeta } from "/home/forge/master.smake.cloud/releases/v1.1.0/pages/index.vue?macro=true";
import { default as loginKjjtPtvnT7Meta } from "/home/forge/master.smake.cloud/releases/v1.1.0/pages/login.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/forge/master.smake.cloud/releases/v1.1.0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entranceL8EYzT1CxEMeta?.name ?? "entrance",
    path: "/entrance",
    meta: entranceL8EYzT1CxEMeta || {},
    component: () => import("/home/forge/master.smake.cloud/releases/v1.1.0/pages/entrance.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/master.smake.cloud/releases/v1.1.0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginKjjtPtvnT7Meta?.name ?? "login",
    path: "/login",
    meta: loginKjjtPtvnT7Meta || {},
    component: () => import("/home/forge/master.smake.cloud/releases/v1.1.0/pages/login.vue").then(m => m.default || m)
  }
]